import React from "react";
import { PopupButton } from "@typeform/embed-react";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="pricing">
          <div className="margin-bottom-45">
            <div className="headline cc-narrow">
              <h3 className="label cc-large cc-primary-text">Pricing</h3>
              <h2 className="display-2">
                The prices I offer are{" "}
                <span className="bold-italic-text">amazing!</span>
              </h2>
            </div>
          </div>
          <div className="pricing-content">
            {/* Pricing Plan 1 */}
            <PricingPlan
              price="$300"
              title="Regular plan"
              features={[
                "Up to 3 hours",
                "All digital photos",
                "10 edited photos",
                "Online Gallery",
              ]}
            />

            {/* Pricing Plan 2 */}
            <PricingPlan
              price="$500"
              title="Deluxe plan"
              features={[
                "Up to 6 hours",
                "All digital photos",
                "20 edited photos",
                "Online Gallery",
              ]}
            />
          </div>

          {/* FAQ Section */}
          {/* <div className="faq-columns">
            <FaqItem
              title="Which payment methods do you accept?"
              answer="Ut nunc, dui sit sit nisl, cras velit lorem. Laoreet gravida adipiscing augue sit justo elit. Mauris bibendum mattis et diam tellus. Auctor mauris felis lobortis tempus."
            />
            <FaqItem
              title="How can I manage my account?"
              answer="Ut nunc, dui sit sit nisl, cras velit lorem. Laoreet gravida adipiscing augue sit justo elit. Mauris bibendum mattis et diam tellus. Auctor mauris felis lobortis tempus."
            />
            <FaqItem
              title="Can I cancel my subscription?"
              answer="Ut nunc, dui sit sit nisl, cras velit lorem. Laoreet gravida adipiscing augue sit justo elit. Mauris bibendum mattis et diam tellus. Auctor mauris felis lobortis tempus."
            />
            <FaqItem
              title="Is my credit card information secure?"
              answer="Ut nunc, dui sit sit nisl, cras velit lorem. Laoreet gravida adipiscing augue sit justo elit. Mauris bibendum mattis et diam tellus. Auctor mauris felis lobortis tempus."
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const PricingPlan = ({ price, title, features }) => {
  const { t } = useTranslation();
  return (
    <div className="pricing-banner background-primary-200">
      <div className="pricing-banner-inner">
        <div className="price-grid">
          <div>
            <h3 className="price">{price}</h3>
            <h2 className="h2-uppercase margin-bottom-none">{title}</h2>
          </div>
          <div className="icon-list">
            {features.map((feature, index) => (
              <div className="icon-list-item" key={index}>
                <div className="icon">
                  <img
                    src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/636bb974a6bf2ad36ce6f647_check.svg"
                    loading="lazy"
                    alt=""
                  />
                </div>
                <p className="icon-list-text">{feature}</p>
              </div>
            ))}
          </div>
          <div className="book">
            <PopupButton id={t("heroSection.buttonId")}>
              <a className="button w-button">Book This Plan</a>
            </PopupButton>
            <div className="spacer-20"></div>
            <p className="margin-bottom-none">
              *Please contact for availability.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FaqItem = ({ title, answer }) => {
  return (
    <div className="faq-item">
      <div className="faq-icon">
        <img
          src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/636bb974728b683e918b7c93_question.svg"
          loading="lazy"
          alt=""
        />
      </div>
      <div className="faq">
        <h5 className="faq-title">{title}</h5>
        <p className="faq-answer">{answer}</p>
      </div>
    </div>
  );
};

export default Pricing;
