import React from "react";
import { useTranslation } from "react-i18next";
import { PopupButton } from "@typeform/embed-react";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="section">
      <div className="container">
        <div className="hero-grid">
          <div
            id="w-node-_7398aa17-1047-1e0e-36b7-8cd5c030ef1d-ccd4aa67"
            data-w-id="7398aa17-1047-1e0e-36b7-8cd5c030ef1d"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
          >
            <h1
              style={{
                marginLeft: "20px",
              }}
              className="display-1"
            >
              {t("heroSection.heading1")}{" "}
              <span className="bold-italic-text">
                {t("heroSection.heading2")}
              </span>{" "}
              {t("heroSection.heading3")}
            </h1>
            <p
              style={{
                marginLeft: "20px",
              }}
              className="paragraph-large margin-bottom-40"
            >
              {t("heroSection.description")}
            </p>
            <PopupButton
              style={{ background: "transparent" }}
              id={t("heroSection.buttonId")}
            >
              <a
                style={{
                  margin: "auto",
                  marginLeft: "20px",
                }}
                className="button w-button"
              >
                {t("heroSection.button")}
              </a>
            </PopupButton>
            <a
              style={{
                margin: "auto",
                marginLeft: "20px",
                marginTop: "20px",
                color: "white",
                background: "#173738",
              }}
              href="/pricing"
              className="button w-button"
            >
              See Pricing!
            </a>
          </div>
          <div
            id="w-node-e1fc57a0-201c-5702-a66b-3ef0c63576cf-ccd4aa67"
            className="hero-image-grid"
            style={{
              marginLeft: "8rem",
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634ff4654c587113891d8b57_text.svg"
              loading="lazy"
              data-w-id="5c2270fd-f6e9-59a9-928a-bcb526c80f6b"
              style={{ opacity: 1 }}
              alt=""
              id="w-node-_5c2270fd-f6e9-59a9-928a-bcb526c80f6b-ccd4aa67"
              className="hero-blume"
            />
            <img
              src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/635bc0ac2ba3d3f2f0d126b6_shape1.svg"
              loading="lazy"
              style={{ opacity: 1 }}
              data-w-id="9c129d25-f5ba-46fb-6e97-c8b19a62030a"
              alt=""
              id="w-node-_9c129d25-f5ba-46fb-6e97-c8b19a62030a-ccd4aa67"
              className="hero-flower"
            />
            <div
              id="w-node-_19655d8b-8f5a-ccfe-823d-a54cb36966d7-ccd4aa67"
              data-w-id="19655d8b-8f5a-ccfe-823d-a54cb36966d7"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="hero-image-grid-item-odd"
            >
              <img
                src={t("heroSection.heroPhoto1")}
                loading="lazy"
                alt="Couple - Blume Photography Webflow Template"
                width="355"
                className="hero-image"
              />
            </div>
            <div
              id="w-node-_97e912a5-3477-ad94-f2ae-759e845df786-ccd4aa67"
              data-w-id="97e912a5-3477-ad94-f2ae-759e845df786"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="hero-image-grid-item-even"
            >
              <img
                src={t("heroSection.heroPhoto2")}
                loading="lazy"
                alt="Couple - Blume Photography Webflow Template"
                width="355"
              />
            </div>
            <div
              id="w-node-ff7bd085-291e-4b31-5fa9-fa80c74e6d52-ccd4aa67"
              data-w-id="ff7bd085-291e-4b31-5fa9-fa80c74e6d52"
              style={{ opacity: 1 }}
              className="hero-image-grid-background background-primary-100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
