import * as React from "react";
import PropTypes from "prop-types";

function ContentLayout({ children }) {
  if (children) {
    return <div>{children}</div>;
  }
  return <div />;
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired, // eslint-disable-line
};

export default ContentLayout;
