import Airtable from "airtable";

class AirtableHelper {
  private static instance: AirtableHelper;
  private airtableBase: any;

  private constructor() {
    this.airtableBase = new Airtable({ apiKey: process.env.AIRTABLE_API_KEY }).base(
      `${process.env.AIRTABLE_BASE_ID}`
    );
  }

  public static getInstance(): AirtableHelper {
    if (!AirtableHelper.instance) {
      AirtableHelper.instance = new AirtableHelper();
    }
    return AirtableHelper.instance;
  }

  public addToAirtable = async (email: string, name?: string, message?: string) => {
    try {
      const records = await this.airtableBase("emailList").create([
        {
          fields: {
            email: email,
            name: name,
            message: message,
          },
        },
      ]);

      console.log(records[0].getId());
    } catch (error) {
      console.error(error);
    }
  };
}

export default AirtableHelper;
