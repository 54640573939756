import React from "react";
import ServicesSection from "../components/ServicesSection";
import Testimonials from "../components/Testimonials";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="about-grid cc-inner-page">
            <div
              id="w-node-_0c0c81e8-ffdc-1c74-dac5-a3ca43acce8e-9ed5ff65"
              className="about-image"
            >
              <img
                src={t("aboutSection.aboutPhoto1")}
                loading="lazy"
                alt="Photographer - Blume Photography Webflow Template"
              />
              <div className="about-grid-bg"></div>
            </div>
            <div
              id="w-node-_0c0c81e8-ffdc-1c74-dac5-a3ca43acce91-9ed5ff65"
              className="about-details"
            >
              <div>
                <h1 className="label cc-large cc-primary-text">
                  {t("aboutSection.meet")}
                </h1>
                <h2 className="display-3 margin-bottom-50">
                  {t("aboutSection.heading")}
                </h2>
              </div>
              <div className="about-inner-grid">
                <div id="w-node-_0c0c81e8-ffdc-1c74-dac5-a3ca43acce9b-9ed5ff65">
                  <img
                    src={t("aboutSection.aboutPhoto2")}
                    loading="lazy"
                    alt="Photographer - Blume Photography Webflow Template"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634950874a6db3b8aa0e0fdd_shape5.svg"
                    loading="lazy"
                    alt=""
                    className="about-shape"
                  />
                </div>
                <div
                  id="w-node-_0c0c81e8-ffdc-1c74-dac5-a3ca43acce9e-9ed5ff65"
                  className="about-content"
                >
                  <h2 className="h2-uppercase">{t("aboutSection.heading2")}</h2>
                  <p>{t("aboutSection.description")}</p>
                  <h4 className="h4-uppercase margin-bottom-none">
                    {t("aboutSection.insta")}
                  </h4>
                  <a href={t("aboutSection.instaUrl")} className="link-body">
                    {t("aboutSection.instaName")}
                  </a>
                  <div className="spacer-20"></div>
                  <h4 className="h4-uppercase margin-bottom-none">
                    {t("aboutSection.email")}
                  </h4>
                  <a href={t("aboutSection.emailTo")} className="link-body">
                    {t("aboutSection.emailShow")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ServicesSection />
      <Testimonials />
    </>
  );
};

export default About;
