import React from "react";
import { useTranslation } from "react-i18next";

const ServicesSection = () => {
  const { t } = useTranslation();
  return (
    <div className="section background-primary-100">
      <div className="container">
        <div className="services-grid">
          <div
            id="w-node-a2359bc5-54f1-908c-92cc-a476cc8f4a82-ccd4aa67"
            data-w-id="a2359bc5-54f1-908c-92cc-a476cc8f4a82"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              opacity: 1,
            }}
            className="services-grid-column-1"
          >
            <h2 className="display-2">
              {t("servicesSection.heading1")}{" "}
              <span className="bold-italic-text">
                {t("servicesSection.heading2")}
              </span>{" "}
              {t("servicesSection.heading3")}
            </h2>
            <div className="paragraph-large">
              {t("servicesSection.description1")}{" "}
              <span className="bold-italic-text">
                {t("servicesSection.description2")}
              </span>{" "}
              <span className="bold-italic-text"></span>{" "}
              {t("servicesSection.description3")}
            </div>
            <img
              src={t("servicesSection.servicesPhoto1")}
              loading="lazy"
              alt="Bride and groom - Blume Photography Webflow Template"
              width="825"
              className="services-image-big"
            />
            <img
              src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634ff4654c587113891d8b57_text.svg"
              loading="lazy"
              style={{ opacity: 1 }}
              data-w-id="e438d6d4-0dfa-fcd7-4a1f-e0a3b4f002e3"
              alt=""
              className="services-blume"
            />
            <img
              src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/635bc0ac0b1d828a2dd4a6be_shape2.svg"
              loading="lazy"
              style={{ opacity: 1 }}
              data-w-id="64a0d20a-534c-c617-c70e-c16866683bdd"
              alt=""
              className="services-shape"
            />
          </div>
          <div
            id="w-node-_78efd06d-c1e1-df60-db23-373d909b8ea5-ccd4aa67"
            data-w-id="78efd06d-c1e1-df60-db23-373d909b8ea5"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              opacity: 1,
            }}
            className="services-grid-column-2"
          >
            <img
              src={t("servicesSection.servicesPhoto2")}
              loading="lazy"
              alt="Couple - Blume Photography Webflow Template"
              width="385"
              className="services-image-small"
            />
            <p className="services-paragraph">
              {t("servicesSection.imageDescription")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
