import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="footer background-primary-100 wf-section">
        <div className="container">
          <div className="footer-grid">
            <div className="footer-left-column">
              <div className="icon-title">
                <img
                  src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634964a3c19d649d290b2d16_instagram2.svg"
                  loading="lazy"
                  alt="Instagram - Blume Photography Webflow Template"
                  className="social-icon"
                />
                <h4 className="h4-uppercase margin-bottom-none">Instagram</h4>
              </div>
              <a href={t("footer.contact.instagramUrl")} className="link-body">
                {t("footer.contact.instagram")}
              </a>
              <div className="spacer-35"></div>
              <div className="icon-title">
                <img
                  src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634964a38f90054aea9b23ac_facebook2.svg"
                  loading="lazy"
                  alt="Facebook - Blume Photography Webflow Template"
                  className="social-icon"
                />
                <h4 className="h4-uppercase margin-bottom-none">Facebook</h4>
              </div>
              <a href={t("footer.contact.facebookUrl")} className="link-body">
                {t("footer.contact.facebook")}
              </a>
            </div>
            <div className="footer-center-column">
              <img
                src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/6990c4e8-58de-4f21-b146-28f34e252c00/public"
                loading="lazy"
                alt=""
                className="footer-logo"
              />
              <p className="margin-bottom-none">{t("footer.belowLogo")}</p>
            </div>
            <div className="footer-right-column">
              <div className="icon-title">
                <img
                  src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634964a3c19d64706c0b2d1b_phone2.svg"
                  loading="lazy"
                  alt="Phone - Blume Photography Webflow Template"
                  className="social-icon"
                />
                <h4 className="h4-uppercase margin-bottom-none">Phone</h4>
              </div>
              <a href={t("footer.contact.phoneCall")} className="link-body">
                {t("footer.contact.phone")}
              </a>
              <div className="spacer-35"></div>
              <div className="icon-title">
                <img
                  src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/634964a29b5933692c62eed1_mail2.svg"
                  loading="lazy"
                  alt="Mail - Blume Photography Webflow Template"
                  className="social-icon"
                />
                <h4 className="h4-uppercase margin-bottom-none">E-Mail</h4>
              </div>
              <a href={t("footer.contact.emailSend")} className="link-body">
                {t("footer.contact.email")}
              </a>
            </div>
          </div>
          <div className="text-right">
            Designed in{" "}
            <span role="img" aria-label="Sun">
              ☀️
            </span>{" "}
            Texas &amp; Built With{" "}
            <span role="img" aria-label="Heart">
              ❤️
            </span>
          </div>
        </div>
      </div>
      <script
        src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6320506a005493560e53f06d"
        type="text/javascript"
        integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
      ></script>
      <script
        src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/js/webflow.4c4a25c0f.js"
        type="text/javascript"
      ></script>
    </>
  );
};

export default Footer;
