import React from "react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import AirtableHelper from "../helpers/AirtableHelper";

const airtableHelper = new AirtableHelper();

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="section wf-section">
        <div className="container">
          <div className="inner-page-wrapper cc-padding-none">
            <div className="margin-bottom-80">
              <div className="headline cc-tiny">
                <img
                  src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/635bc0acd37e4802687dc97b_shape3.svg"
                  loading="lazy"
                  alt=""
                  className="headline-flower"
                />
                <h1 className="display-2">Contact me</h1>
                <p className="paragraph-large">
                  Hello! Looks like you want to contact me for further! Fill out
                  the form below and I'll send a personalized offer to you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section contact wf-section">
        <div class="container">
          <div class="form-block">
            <div data-tf-live="01HCNKJDZNQPEXBD8H0JG5PCXN"></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
