/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { TextAlignJustified, Cross } from "akar-icons";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";
import "../../styles/webflow.css";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState("none");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown("remote");
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = "auto";
  };

  return (
    <header>
      <div
        className="navbar"
        style={{
          willChange: "background",
          backgroundColor: "rgba(255, 255, 255, 0)",
        }}
      >
        <div
          className="top-bar"
          style={{
            willChange: "opacity, width, height",
            opacity: 1,
            height: "8px",
          }}
        ></div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease-out"
          data-easing2="ease-out"
          role="banner"
          className="navbar-inner w-nav"
        >
          <div className="container">
            <div className="navbar-wrapper">
              <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                <div className="nav-menu-flex">
                  <ul role="list" className="nav-menu w-list-unstyled">
                    <li>
                      <a
                        href="/"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blog"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="/pricing"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                  <a
                    href="/"
                    className="navbar-brand w-nav-brand"
                    aria-label="home"
                  >
                    <img
                      src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/6990c4e8-58de-4f21-b146-28f34e252c00/public"
                      loading="lazy"
                      height=""
                      alt=""
                      className="logo"
                      style={{ willChange: "width, height", height: "160px" }}
                    />
                  </a>
                  <ul role="list" className="nav-menu cc-right w-list-unstyled">
                    <li>
                      <a
                        href="/gallery"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a
                        href="/about"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact"
                        className="nav-link"
                        style={{ willChange: "width, height", height: "143px" }}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div
              className="navbar-border"
              style={{ willChange: "opacity", opacity: 1 }}
            ></div>
          </div>
        </div>
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-easing="ease-out"
          data-easing2="ease-out"
          data-w-id="3af8bc8a-5cd4-7ca1-7fbd-f5ab1821d78d"
          role="banner"
          className="navbar-inner-mobile w-nav"
        >
          <div className="container">
            <div className="navbar-wrapper-mobile">
              <a href="#" className="navbar-brand-mobile w-nav-brand">
                <img
                  src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/6990c4e8-58de-4f21-b146-28f34e252c00/public"
                  loading="lazy"
                  height="80"
                  alt=""
                  className="logo"
                  style={{ willChange: "width, height", height: "120px" }}
                />
              </a>
              <div
                className="menu-button w-nav-button"
                style={{ WebkitUserSelect: "text" }}
                aria-label="menu"
                role="button"
                tabIndex="0"
                aria-controls="w-nav-overlay-1"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <Hamburger
                  hamburgerOpen={hamburgerOpen}
                  setHamburgerStatus={setHamburgerStatus}
                />
              </div>
            </div>
            <div
              className="navbar-border"
              style={{ willChange: "opacity", opacity: 1 }}
            ></div>
            <nav
              role="navigation"
              className={`nav-menu-wrapper-mobile w-nav-menu ${
                hamburgerOpen ? "show" : "hide"
              }`}
              style={{ transform: "translateY(0px) translateX(0px)" }}
            >
              <div className="nav-menu-flex-mobile">
                <ul role="list" className="nav-menu w-list-unstyled">
                  <li>
                    <a href="/" className="nav-link-mobile">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/blog" className="nav-link-mobile">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/pricing" className="nav-link-mobile">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="/gallery" className="nav-link-mobile">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="nav-link-mobile">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/contact" className="nav-link-mobile">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-1"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>

      <script
        src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6246f628ff60cf28f937a441"
        type="text/javascript"
        integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
        crossorigin="anonymous"
      ></script>
      <script
        src="https://assets.website-files.com/6246f628ff60cf28f937a441/js/webflow.a3ec327ce.js"
        type="text/javascript"
      ></script>
    </header>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            console.log("clicked");
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = "auto";
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = "hidden";
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: "0.5rem" }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: "0.5rem 0 0.5rem 0.7rem" }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? "block" : "none",
          margin: "none",
          padding: "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
