import React from "react";
import { useTranslation } from "react-i18next";

function Gallery() {
  const { t } = useTranslation();
  return (
    <div className="section wf-section">
      <div className="container">
        <div className="inner-page-wrapper">
          <div className="headline-grid margin-bottom-80">
            <div
              id="w-node-_5a9ec1e8-2d6b-5de0-5780-a13cc91a70c4-0234404b"
              className="headline"
            >
              <img
                src="https://uploads-ssl.webflow.com/6320506a005493560e53f06d/635bc0acd37e4802687dc97b_shape3.svg"
                loading="lazy"
                alt=""
                className="headline-flower"
              />
              <h1 className="display-2">
                {t("gallerySection.header")}
                <span></span>
              </h1>
              <p className="paragraph-large">
                {t("gallerySection.description")}
              </p>
            </div>
          </div>
          <div
            data-current="All"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="gallery-tabs w-tabs"
          >
            <div className="w-tab-content">
              <div
                data-w-tab="All"
                className="w-tab-pane w--tab-active"
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
              >
                <div className="gallery-columns">
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto1")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto2")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto3")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto4")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto5")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto6")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto7")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto8")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto9")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto10")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto11")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto12")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  {/* <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto13")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto14")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a>
                  <a
                    href="#"
                    data-w-id="10d24647-0652-036f-b3b8-0ed5d3cd441b"
                    className="gallery-item-lightbox-link w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <div className="image-overflow">
                      <img
                        sizes="100vw"
                        src={t("gallerySection.galleryPhoto15")}
                        loading="lazy"
                        alt="Couple - Blume Photography Webflow Template"
                        className="image-hover"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        }}
                      />
                    </div>
                  </a> */}
                  {/* Add more gallery items here */}
                </div>
              </div>
              <div
                data-w-tab="Wedding"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              >
                {/* Add wedding-specific gallery items here */}
              </div>
              <div
                data-w-tab="Couples"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-2"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-2"
              >
                {/* Add couples-specific gallery items here */}
              </div>
              <div
                data-w-tab="Family"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-3"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-3"
              >
                {/* Add family-specific gallery items here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
