import React from "react";

import Newsletter from "../components/Newsletter";
import ContentList from "../components/ContentList";
import Blog from "../components/Blog";
import Hero from "../components/Hero";
import ServicesSection from "../components/ServicesSection";
import Jobs from "../components/Jobs";
import Testimonials from "../components/Testimonials";

export default function index() {
  return (
    <>
      <Hero />
      <ServicesSection />
      {/* <Jobs /> */}
      <Testimonials />
      {/* <Blog /> */}
    </>
  );
}
